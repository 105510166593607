import React from "react";
// import { graphql } from 'gatsby';
// import Img from 'gatsby-image';
import styled from '@emotion/styled';

import SEO from "../components/seo"
import PageTitle from '../components/PageTitle';
import ComingSoon from '../components/ComingSoon';

// import dataYML from '../../content/data/about/about.yml';

const AboutPage = ({ data }) => (
	<>
		<SEO title="About Tony" />
		<CENTER>
			<PageTitle title="About Tony"/>
			<CONTAINER>
				{/* <IMG fixed={data.fileName.childImageSharp.fixed} alt="" /> */}
				<ComingSoon />
			</CONTAINER>
			{/* {dataYML['tony']} */}
			{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
		</CENTER>
	</>
);

// export const query = graphql`
//   query {
//     fileName: file(relativePath: { eq: "about/tony_bronzo.jpg" }) {
//       childImageSharp {
// 		fixed (width: 125, height: 300) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

const CENTER = styled('main')`
  padding-bottom: 80px;
  width: 100vw;
  margin: 0 auto;
  background: #FFF;

	// image switches to fullscreen
	@media (max-width: 500px) {
		width: 100%;
  }
`;

const CONTAINER = styled('div')`
	max-width: 650px;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;

	@media (max-width: 500px) {
		flex-direction: column;
		align-items: center;
	}
`;

// const IMG = styled(Img)`
// 	border-radius: 25px;
// `;

export default AboutPage;
