import React from 'react';
import styled from '@emotion/styled';

function ComingSoon({ isFullWidth, text = "Coming Soon" }) {
    const ComingSoonText = (
        <P>
            <span role="img" aria-label="">🚧</span>
                &nbsp;&nbsp;{text}&nbsp;&nbsp;
            <span role="img" aria-label="">🏗</span>
        </P>
    );

    if (isFullWidth) {
        return (
            <CONTAINER>
                {ComingSoonText}
            </CONTAINER>
        );
    }

    return ComingSoonText;
}

const CONTAINER = styled('div')`
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 200px 30px;

    @media (max-width: 500px) {
        padding: 50px 0;
    }
`;

const P = styled('div')`
	${'' /* padding-left: 100px; */}
	font-size: 35px;
	align-self: center;
	font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

	@media (max-width: 500px) {
		font-size: 25px;
		padding-left: 0;
		margin-top: 50px;
	}
`;

export default ComingSoon;